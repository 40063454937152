<template>
  <div class="" v-if="formData.id">
    <LocationForm :form-data="formData" title="Edit location"/>
  </div>
</template>

<script>
import LocationForm from "@/views/pages/locations/Location-Form"
import LocationService from "@/services/LocationService"

export default {
  name: "Edit",
  components: {
    LocationForm
  },
  data() {
    return {
      itemId: this.$route.params.itemId,
      LocationService: new LocationService(),
      formData: {
        id: '',
        details: '',
        name: '',
        longitude: '',
        latitude: '',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.LocationService.show(this.itemId).then(res => {
        console.log(res)
        this.formData = {
          id: res.id,
          name: res.name,
          details: res.details,
          longitude: +res.longitude,
          latitude: +res.latitude,
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
